import React from "react"
import { Link } from "gatsby"
import logoSave from '../images/logo-save.svg'
import { Button } from '@material-ui/core';
import { defaultStaticContext } from '../context/StaticContext'
import sacoche from '../images/sacoche.svg'
import quickPocket from '../images/quick-pocket.svg'
import html2canvas from 'html2canvas';
import countdown from '../images/countdown.gif'
import * as htmlToImage from 'html-to-image';
import QRCode from 'qrcode'
import download from 'downloadjs'
import { useTranslation } from "react-i18next"

const Save = ({ uuid, setStage, qrcodelink, currentInfo, outofstock, color, currentDesign }) => {
  // React.useEffect(() => {
  //   var myCanvas = document.getElementById('qrcode');
  //   var ctx = myCanvas.getContext('2d');
  //   var img = new Image;
  //   img.src = qrcodelink;
  //   ctx.canvas.width = 200;
  //   ctx.canvas.height = 200;
  //   img.onload = () => {
  //     ctx.drawImage(img,0,0,200,200);
  //   }
  // }, []);
  React.useEffect(() => {
    const canvasFront = document.querySelector('#canvas-container-front').innerHTML;
    // const frontImage = document.querySelector('#svgOutput')
    // frontImage.innerHTML = canvasFront
  }, []);

  const saveQrcode = () => {
    
    const node = document.getElementById('qrcodebox')
    // node.src = qrcodelink
    console.log(node)
    htmlToImage.toPng(node)
    .then(function (dataUrl) {
      download(dataUrl, "mypass.png", "image/png");
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
    // htmlToImage.toPng(node)
    // .then(function (dataUrl) {
    //   var img = new Image();
    //   img.src = dataUrl;
    //   document.body.appendChild(img);
    // })
    // .catch(function (error) {
    //   console.error('oops, something went wrong!', error);
    // });
  }

  const { t } = useTranslation()
  
  return (
    <div className="savePage">
      <img className="savePage__image" src={countdown} />
      <h2>{ t('checkout.saveTitle') }</h2>
      <div className="savePage__wrapper">
        <div className="mobile savePage__desc">{ t('checkout.saveDesc') }</div>
        {/* <div className="qrcodebox__container">
          <div id="qrcodebox" className="qrcodebox" crossOrigin="anonymous" style={{ backgroundColor: color }}>
            <hr/>
            <img src={logoSave} />
            <div className="qrcodebox__wrapper">
              <div className="sharebox__container">
                <div id="sharebox" className="sharebox" style={{ backgroundColor: color }}>
                  <div id="svgOutput" className="sharebox__svg">
                  </div>
                  <div className="sharebox__product">
                    {currentDesign.product === "sacoche" ? (
                      <img
                        src={sacoche}
                        className="sacoche"
                      />
                    ) : (
                      <img
                        src={quickPocket}
                        className="quickPocket"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="qrcodebox__link">
                createmygregory.com
              </div>
            </div>
          </div>
        </div> */}
        <div className="info__container">
          <div className="savePage__desc desktop">{ t('checkout.saveDesc') }</div>
          <div className="buttonGroup stickToBottom">
            {/* <Button className="basicButton outline" onClick={() => setStage('share')}>
              { t('checkout.shareDesign') }
            </Button> */}
            <Link to="/product" className="basicButton outline">
              <span>{ t('postLaunch.explore') }</span>
            </Link>
            {/* <Button className="basicButton" onClick={() => saveQrcode()}>
              { t('checkout.saveRsvp') }
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  )

  
}

export default Save;